import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"

const moreGp = () => {
  return (
    <Layout>
      <SEO
        title="How to get more GP in PES 2021 myClub"
        description="Learn all the best ways to get GP easily in PES myClub 2021, so that you can quickly improve your team."
      />
      <div className="container">
        <h1>How to get more GP in PES 2021 myClub</h1>
        <ul className="breadcrumb" style={{ paddingTop: "1rem" }}>
          <li>
            <Link to="/">Home</Link>
          </li>
          <li>How to get more GP in PES 2021 myClub</li>
        </ul>
        <p>
          Every action you perform for the first time in PES will get you GP. If
          you switch to a new tactic in-game, come up with a new skill move or
          even a goal celebration, you will be rewarded with a couple hundred
          GP.
        </p>
        <p>
          After a while though, obtaining GP will become harder and harder. And
          you will need GP to{" "}
          <Link to="/signing-new-players-agents/">spin Agents</Link>,{" "}
          <Link to="/contract-stamina-renewal/">renew contracts</Link> and{" "}
          <Link to="/signing-new-players-scout-combinations/">buy scouts</Link>.
        </p>
        <p>
          Konami will sometimes offer you GP as an incentive (for example,
          during a festive campaign) or because of technical problems that
          prevented players from playing online. Those bonuses are always
          welcome, but that's something you can't really control.
        </p>
        <p>
          You can save some GP by using "Contract Renewal Tickets", when
          available, to renew your most expensive player's contracts. But if you
          use them every time, they will run out rather quickly.
        </p>
        <p>
          In previous editions of PES myClub, you could earn GP by playing
          Offline Game Modes, such as Master League, Training or Become a
          Legend. This used to be a great way to gain some GP, but no longer
          possible since PES 2020.
        </p>
        <p>So what can you do? Here are 3 things to keep in mind.</p>
        <h2>#1: Daily Login</h2>
        <p>
          You will get a reward every day by simply logging in. In PES 2021,
          this can be 300 eFootball Points (used to be 1.500 GP in previous
          versions), 30/40 coins, or 12 Stamina Recovery items.
        </p>
        <p>The order is the following:</p>
        <ul>
          <li>Day 1: 300 eFootball Points</li>
          <li>Day 2: 12 Stamina Recovery Items</li>
          <li>Day 3: 30 coins</li>
          <li>Day 4: 300 eFootball Points</li>
          <li>Day 5: 12 Stamina Recovery Items</li>
          <li>Day 6: 30 coins</li>
          <li>Day 7: 300 eFootball Points </li>
          <li>Day 8: 12 Stamina Recovery Items</li>
          <li>Day 9: 40 coins</li>
        </ul>
        <p>
          Since you have already logged in, I would recommend going for that 1st
          victory of the day, which gives you a 3.000 GP bonus. You can easily
          make at least 4k GP just by winning a single game every day (even a
          stress-free match VS COM).
        </p>
        <p>
          You won't make a lot of GP this way, but it all adds up. The real
          increase in your GP balance will come in the next tips (especially
          "Sims" and by playing "Matchday").
        </p>
        <h2>#2: SIMs</h2>
        <p>
          This is a method some people hate, but it’s guaranteed to bring you
          great results. It works very well if you can keep an eye on Pro
          Evolution Soccer every 15 minutes or so while you multitask - you’re
          basically earning GP in the background.
        </p>
        <p>
          First, you create a team with a cheap manager, 11 white balls, and a
          stacked bench - the idea here is to have a 5-star team due to the
          players that are benched. Then, you turn Auto-Subs “OFF” so that they
          are never used (so that you don’t need to pay for their contracts).
        </p>
        <p>
          This will result in losing pretty much every game (since your white
          balls will be facing black ball teams), but every 15 minutes you will
          be making 1320 GP. You may even win or draw some matches here and
          there, so that will be a nice bonus.
        </p>
        <p>
          You will receive a scout after every game, so after 10 games you
          simply don’t renew your players’ contracts - instead, you convert them
          into trainers (to feed them to your favourite players) and sign 10 new
          players to do this all over again.
        </p>
        <p>
          This is obviously not a very exciting method, but it does have 2 big
          upsides:
        </p>
        <ul>
          <li>you’re earning GP with little effort;</li>
          <li>you get more players to convert into EXP trainers.</li>
        </ul>
        <p>
          You should use your cheapest manager to stay as profitable as
          possible. If you’re lucky, your team will earn just enough points for
          a free contract extension, but after so many losses, you will probably
          need to pay for the manager's contract renewal.
        </p>
        <p>
          If you create a Cloud Match team, other players will also face your
          team in their SIM games. This means you will receive some additional
          GP once a week as the outcome of those matches, provided other users
          actually faced your team.
        </p>
        <p>
          I advise you to pick your strongest team, as you won't need to renew
          their contracts and you increase your chances of winning those
          matches.
        </p>
        <h2>#3: Play the game!</h2>
        <p>
          Ironically enough, you can also earn GP by simply… playing the game!
          Who would have thought?
        </p>
        <h3>Earning GP by playing myClub (VS COM)</h3>
        <p>
          As mentioned above, one easy thing you should do is play at least once
          a day. The First Victory of the Day is always worthwhile (3.000 GP) -
          you can simply win an easy VS COM game and call it a day.
        </p>
        <p>
          Every Monday, Konami will promote a new VS COM challenge to complete -
          You can find it under the "What's New" section.
        </p>
        <p>
          On a normal week, you will get 5.000 GP by completing the challenge.
          Konami rarely introduces new rewards, so this one is pretty much a
          given. After completing it for the first time, you will get 500 GP as
          a bonus for each time you finish.
        </p>
        <p>
          Those challenges are usually fairly accessible; assuming you’re a
          decent player, you don’t even need to use your best players, to ensure
          that contract renewal costs stay low.
        </p>
        <p>
          Usually I just use White Balls from my SIM team, and then convert them
          into trainers.
        </p>
        <h3>Earning GP by completing Online competitions</h3>
        <p>
          Every Monday, you will also have a new Online challenge to complete
          under the "What's New" section.
        </p>
        <p>
          On a normal week, you can get 10.000 GP for completing the online
          competition, which usually consists of earning 4 points after 3 games.
          After completing it for the first time, you will get 500 GP as a bonus
          for each time you finish.
        </p>
        <p>
          Some Online games have multipliers, which increases the odds of
          gaining more GP. They usually occur if you use all 3 Players of the
          Week (of that exact week), or if you encounter a “Special Match”,
          where more GP is at stake if you win (and sometimes more rewards, such
          as EXP trainers).
        </p>
        <p>
          When playing Online, Co-Op is a game mode that is very rewarding -
          even better if you have 6 players playing the game and you end up on
          the winning side. Only a portion of your players are picked to each
          game, so only some contracts will be used.
        </p>
        <p>
          Online Ranked matches are not amazingly profitable, apart from those
          "Special Matches" mentioned above. But by playing them you can gain
          points which will help you rank in the PES Weekly League.
        </p>
        <p>
          In the PES Weekly League you can earn as little as 7.000 GP and as
          much as 25.000 GP a week in a low division, depending on your position
          at the end of the weekly competition (Wednesday evening).
        </p>
        <p>
          If you're lucky, you can steal a lot of points from another player and
          finish in one of the top positions.
        </p>
        <p>
          If you're new to the PES Weekly League,{" "}
          <Link to="/game-modes-challenges/">have a look at how it works</Link>.
        </p>
        <h3>Earning GP by playing and spectating Matchday</h3>
        <p>
          This is a new competition which was first introduced in PES 2020.
          Matchday is a very time-limited event that usually awards you
          eFootball Points, Special Agents and Trainers, depending on how much
          you play and how you perform against other players online during that
          time.
        </p>
        <p>
          Regarding GP, you will get 15.000 or 10.000 depending on picking the
          winning side, and additional 10.000 by spectating the Grand Final. You
          can simply play 1 Matchday match and then spectate the final and
          you're guaranteed a minimum of 20.000 GP every day.
        </p>
        <p>
          Have a look at <Link to="/game-modes-challenges/">this section</Link>{" "}
          to understand how Matchday works in more detail.
        </p>
      </div>
    </Layout>
  )
}

export default moreGp
